.container__conta {
  padding: 5vh;
  display: grid;
  place-items: center;
}

.content__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 70vw;
  height: 80vh;
  background: hsla(0, 0%, 100%, 0.952);
  border-radius: 15px 0 15px 0;
  -webkit-box-shadow: 0px 0px 17px 12px rgba(0, 0, 0, 0.199);
  box-shadow: 0px 0px 17px 12px rgba(0, 0, 0, 0.199);
  padding: 2vh;
}

.content__container h1 {
  color: hsl(0, 0%, 33%);
}

.title__q2 p {
  text-align: center;
  color: hsl(0, 0%, 33%);
  margin: 3vh 0;
}

.content__container input {
  padding: 10px;
  margin: 1vh 2vh 4vh 0;
  width: 40vh;
  height: 5vh;
}

.content__container input::placeholder {
  color: hsl(0, 0%, 63%);
}

.content__container h2 {
  color: hsl(0, 91%, 70%);
}

.label__rfe {
  display: flex;
  align-items: center;
  justify-content: center;
  place-items: center;
  flex-direction: row;
}

.label__rfe h1 {
  margin: 1vh 2vh 4vh 0;
  color: hsl(0, 0%, 33%);
}

.inp__left__content {
  width: 33vh !important;
  border: 1px solid hsl(229, 30%, 37%);
  border-radius: 10px;
}

.content__container {
  display: flex;
  align-items: center;
  justify-content: center;
  place-items: center;
}

.content__container hr {
  border: 1px solid hsl(0, 0%, 52%);
  border-style: dotted;
  border-bottom: none;
  margin: 2.3vh;
}

.total__result {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.total__result h1 {
  color: hsl(143, 52%, 40%);
}
.total__result h2 {
  color: hsl(177, 52%, 40%);
}

.total__result span {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 10px;
  color: hsl(0, 0%, 33%);
}

.btn__restaurant {
  width: 20vh;
  cursor: pointer;
  border: none;
  background-color: hsl(209, 39%, 46%);
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}
