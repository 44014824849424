.container {
  width: 100%;
  display: grid;
  place-items: center;
  /* text-align: center; */
  /* background: #cecece; */
  height: 100vh;
  padding: 10vh;
}

.content {
  background: hsl(212, 35%, 8%);
  width: 60vh;
  height: 60vh;
  padding: 5vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  border-radius: 10px;

  -webkit-box-shadow: 0px 0px 17px 12px rgba(0, 0, 0, 0.199);
  box-shadow: 0px 0px 17px 12px rgba(0, 0, 0, 0.199);
}

.header {
  text-align: center;
  padding: 0 0 10vh 0;
}

.header p {
  margin-top: 1vh;
  color: white;
}

.links {
  /*display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 10px 10px;
  grid-template-areas:
    "div1 div2 div3"
    "div4 div5 div6"
    "div7 div7 div7";*/

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.modal {
  width: auto;
  height: 10vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  place-items: center;
  background: hsl(0, 95%, 62%);
  border-radius: 5px;

  -webkit-box-shadow: 0px 0px 17px 12px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 0px 17px 12px rgba(0, 0, 0, 0.09);

  transition: ease-in-out 0.3s;
}

.modal:hover {
  background: hsl(0, 99%, 28%);
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 1 / 3 / 2 / 4;
}
.div4 {
  grid-area: 2 / 1 / 3 / 2;
}
.div5 {
  grid-area: 2 / 2 / 3 / 3;
}
.div6 {
  grid-area: 2 / 3 / 3 / 4;
}
.div7 {
  grid-area: 3 / 1 / 4 / 2;
}
.div8 {
  grid-area: 3 / 2 / 4 / 3;
}
.div9 {
  grid-area: 3 / 3 / 4 / 4;
}
