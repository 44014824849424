.container {
  width: 100%;
  display: grid;
  place-items: center;
  height: 80vh;
  padding: 10vh;
}

.header__imc {
  text-align: center;
  padding: 3vh;
}

.header__imc p {
  margin-top: 2vh;
  color: white;
}

.result h2 {
  margin-top: 1vh;
  color: hsl(0, 68%, 58%);
}

.result span {
  margin-top: 1vh;
  color: white;
  font-size: 1rem;
}

.formulario__imc {
  display: grid;
  place-items: center;
}

.formulario__imc input {
  padding: 10px;
  margin: 1vh 2vh 4vh 2vh;
  width: 30vh;
  height: 5vh;
}

.formulario__imc input::placeholder {
  color: hsl(199, 88%, 62%);
}

.formulario__imc h2 {
  color: hsl(187, 100%, 51%);
  text-align: center;
}

.formulario__imc .btn__imc {
  width: 20vh;
  cursor: pointer;
  border: none;
  background-color: hsl(209, 39%, 46%);
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.inp {
  border: 1px solid hsl(229, 30%, 37%);
  border-radius: 10px;
}
