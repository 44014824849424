@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 100%;
  display: grid;
  place-items: center;
  /* text-align: center; */
  /* background: #cecece; */
  height: 100vh;
  padding: 10vh;
}

.content {
  background: hsl(212, 35%, 8%);
  width: 60vh;
  height: 60vh;
  padding: 5vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  border-radius: 10px;
  box-shadow: 0px 0px 17px 12px rgba(0, 0, 0, 0.199);
}

.header {
  text-align: center;
  padding: 0 0 10vh 0;
}

.header p {
  margin-top: 1vh;
  color: white;
}

.links {
  /*display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 10px 10px;
  grid-template-areas:
    "div1 div2 div3"
    "div4 div5 div6"
    "div7 div7 div7";*/

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.modal {
  width: auto;
  height: 10vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  place-items: center;
  background: hsl(0, 95%, 62%);
  border-radius: 5px;
  box-shadow: 0px 0px 17px 12px rgba(0, 0, 0, 0.09);

  transition: ease-in-out 0.3s;
}

.modal:hover {
  background: hsl(0, 99%, 28%);
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 1 / 3 / 2 / 4;
}
.div4 {
  grid-area: 2 / 1 / 3 / 2;
}
.div5 {
  grid-area: 2 / 2 / 3 / 3;
}
.div6 {
  grid-area: 2 / 3 / 3 / 4;
}
.div7 {
  grid-area: 3 / 1 / 4 / 2;
}
.div8 {
  grid-area: 3 / 2 / 4 / 3;
}
.div9 {
  grid-area: 3 / 3 / 4 / 4;
}

.main__ {
  width: 100%;
  height: 100vh;
  background: linear-gradient(146deg, rgb(21, 31, 39) 0%, rgb(25, 47, 56) 100%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none;
  color: rgb(243, 243, 243);
}

h1 {
  color: rgb(253, 253, 253);
}

.nav {
  padding: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 10vh;
  background: fixed hsl(231, 27%, 10%);
}

.container__nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2vh;
}

.container__nav a {
  margin-left: 2vh;
  color: hsl(204, 100%, 66%);
}

.container__nav a {
  display: inline-block;
  vertical-align: middle;
  transition-property: color;
  transition-duration: 0.5s;
}

.container__nav a:hover {
  color: white;
}

.container {
  width: 100%;
  display: grid;
  place-items: center;
  height: 80vh;
  padding: 10vh;
}

.header__imc {
  text-align: center;
  padding: 3vh;
}

.header__imc p {
  margin-top: 2vh;
  color: white;
}

.result h2 {
  margin-top: 1vh;
  color: hsl(0, 68%, 58%);
}

.result span {
  margin-top: 1vh;
  color: white;
  font-size: 1rem;
}

.formulario__imc {
  display: grid;
  place-items: center;
}

.formulario__imc input {
  padding: 10px;
  margin: 1vh 2vh 4vh 2vh;
  width: 30vh;
  height: 5vh;
}

.formulario__imc input::-webkit-input-placeholder {
  color: hsl(199, 88%, 62%);
}

.formulario__imc input:-ms-input-placeholder {
  color: hsl(199, 88%, 62%);
}

.formulario__imc input::-ms-input-placeholder {
  color: hsl(199, 88%, 62%);
}

.formulario__imc input::placeholder {
  color: hsl(199, 88%, 62%);
}

.formulario__imc h2 {
  color: hsl(187, 100%, 51%);
  text-align: center;
}

.formulario__imc .btn__imc {
  width: 20vh;
  cursor: pointer;
  border: none;
  background-color: hsl(209, 39%, 46%);
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.inp {
  border: 1px solid hsl(229, 30%, 37%);
  border-radius: 10px;
}

.container__conta {
  padding: 5vh;
  display: grid;
  place-items: center;
}

.content__container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 70vw;
  height: 80vh;
  background: hsla(0, 0%, 100%, 0.952);
  border-radius: 15px 0 15px 0;
  box-shadow: 0px 0px 17px 12px rgba(0, 0, 0, 0.199);
  padding: 2vh;
}

.content__container h1 {
  color: hsl(0, 0%, 33%);
}

.title__q2 p {
  text-align: center;
  color: hsl(0, 0%, 33%);
  margin: 3vh 0;
}

.content__container input {
  padding: 10px;
  margin: 1vh 2vh 4vh 0;
  width: 40vh;
  height: 5vh;
}

.content__container input::-webkit-input-placeholder {
  color: hsl(0, 0%, 63%);
}

.content__container input:-ms-input-placeholder {
  color: hsl(0, 0%, 63%);
}

.content__container input::-ms-input-placeholder {
  color: hsl(0, 0%, 63%);
}

.content__container input::placeholder {
  color: hsl(0, 0%, 63%);
}

.content__container h2 {
  color: hsl(0, 91%, 70%);
}

.label__rfe {
  display: flex;
  align-items: center;
  justify-content: center;
  place-items: center;
  flex-direction: row;
}

.label__rfe h1 {
  margin: 1vh 2vh 4vh 0;
  color: hsl(0, 0%, 33%);
}

.inp__left__content {
  width: 33vh !important;
  border: 1px solid hsl(229, 30%, 37%);
  border-radius: 10px;
}

.content__container {
  display: flex;
  align-items: center;
  justify-content: center;
  place-items: center;
}

.content__container hr {
  border: 1px solid hsl(0, 0%, 52%);
  border-style: dotted;
  border-bottom: none;
  margin: 2.3vh;
}

.total__result {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.total__result h1 {
  color: hsl(143, 52%, 40%);
}
.total__result h2 {
  color: hsl(177, 52%, 40%);
}

.total__result span {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 10px;
  color: hsl(0, 0%, 33%);
}

.btn__restaurant {
  width: 20vh;
  cursor: pointer;
  border: none;
  background-color: hsl(209, 39%, 46%);
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.container__random__number {
  padding: 5vh;
  display: grid;
  place-items: center;
}

.content__container__q3 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 60vw;
  height: 70vh;
  background: hsla(0, 0%, 100%, 0.952);
  border-radius: 15px;
  box-shadow: 0px 0px 17px 12px rgba(0, 0, 0, 0.199);
  padding: 2vh;
}

.content__container__q3 h1 {
  color: hsl(0, 0%, 33%);
  text-align: center;
}

.form__q3 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.title__q3,
.result__q3 {
  color: hsl(0, 0%, 33%);
}

.btn__q3 {
  width: 20vh;
  height: 5vh;
  cursor: pointer;
  border: none;
  background-color: hsl(209, 39%, 46%);
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.btn__q3:hover {
  transition: ease-in-out 0.2s;
  background-color: hsl(209, 62%, 52%);
}

.form__q3 h2 {
  color: hsl(0, 0%, 33%);
}

.tentativas__q3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tentativas__q3 h2,
.tentativas__q3 span {
  color: hsl(0, 0%, 33%);
  font-size: 1.2rem;
}

.tentativas__q3 span {
  margin-left: 10px;
  font-weight: bold;
}

.inp__q3 {
  width: 10vh;
  height: 6vh;
  padding: 10px;
  margin: 8vh 0 2vh 0;
  border-radius: 10px;
  border: 1px solid hsla(0, 0%, 33%, 0.39);
  -webkit-appearance: none;

  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

