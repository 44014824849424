@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.container__random__number {
  padding: 5vh;
  display: grid;
  place-items: center;
}

.content__container__q3 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 60vw;
  height: 70vh;
  background: hsla(0, 0%, 100%, 0.952);
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 17px 12px rgba(0, 0, 0, 0.199);
  box-shadow: 0px 0px 17px 12px rgba(0, 0, 0, 0.199);
  padding: 2vh;
}

.content__container__q3 h1 {
  color: hsl(0, 0%, 33%);
  text-align: center;
}

.form__q3 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.title__q3,
.result__q3 {
  color: hsl(0, 0%, 33%);
}

.btn__q3 {
  width: 20vh;
  height: 5vh;
  cursor: pointer;
  border: none;
  background-color: hsl(209, 39%, 46%);
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: bold;
}

.btn__q3:hover {
  transition: ease-in-out 0.2s;
  background-color: hsl(209, 62%, 52%);
}

.form__q3 h2 {
  color: hsl(0, 0%, 33%);
}

.tentativas__q3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tentativas__q3 h2,
.tentativas__q3 span {
  color: hsl(0, 0%, 33%);
  font-size: 1.2rem;
}

.tentativas__q3 span {
  margin-left: 10px;
  font-weight: bold;
}

.inp__q3 {
  width: 10vh;
  height: 6vh;
  padding: 10px;
  margin: 8vh 0 2vh 0;
  border-radius: 10px;
  border: 1px solid hsla(0, 0%, 33%, 0.39);
  -webkit-appearance: none;

  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
