@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none;
  color: rgb(243, 243, 243);
}

h1 {
  color: rgb(253, 253, 253);
}

.nav {
  padding: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 10vh;
  background: fixed hsl(231, 27%, 10%);
}

.container__nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2vh;
}

.container__nav a {
  margin-left: 2vh;
  color: hsl(204, 100%, 66%);
}

.container__nav a {
  display: inline-block;
  vertical-align: middle;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.container__nav a:hover {
  color: white;
}
